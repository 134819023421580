import React, { useState } from "react";
import Input from "../Input";
import Checkbox from "../Input/Checkbox";

const CreateFeeConfirmation = ({
  setFormValue,
}: {
  organisation: any;
  setFormValue: Function;
}) => {
  const [percentage, setPercentFee] = useState("");
  const [flatFee, setFlatFee] = useState("");
  const [isAffiliate, setIsAffiliate] = useState(false);

  return (
    <div className="space-y-4">
      <Input
        label="Percentage"
        name="percentage"
        placeholder="0.00%"
        value={percentage}
        onChange={(event: any) => {
          setPercentFee(event.target.value);
          setFormValue({
            name: event.target.name,
            value: event.target.value,
          });
        }}
      />
      <Input
        label="Transaction"
        name="flatFee"
        placeholder="£0.00"
        value={flatFee}
        onChange={(event: any) => {
          setFlatFee(event.target.value);
          setFormValue({
            name: event.target.name,
            value: event.target.value,
          });
        }}
      />
      <Checkbox
        value="isAffiliate"
        checked={isAffiliate}
        label="Affiliate fee"
        onChange={(event: any) => {
          setIsAffiliate(event.target.checked);
          setFormValue({
            name: "affiliate",
            value: event.target.checked,
          });
        }}
      />
    </div>
  );
};

export default CreateFeeConfirmation;
