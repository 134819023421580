import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import Logo from "../Assets/logo.svg";
import { classNames } from "../lib/helpers";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const navigation = [
  {
    name: "Dashboard",
    href: "/",
    icon: ["fal", "tachometer-alt"] as IconProp,
  },
  {
    name: "Clubs",
    href: "/clubs",
    icon: ["far", "shield-alt"] as IconProp,
  },
  {
    name: "Onboarding",
    href: "/onboarding",
    icon: ["fal", "rocket"] as IconProp,
  },
  {
    name: "Payments",
    href: "/payments",
    icon: ["fal", "credit-card"] as IconProp,
  },
  {
    name: "Payouts",
    href: "/payouts",
    icon: ["fal", "file-invoice"] as IconProp,
  },
  {
    name: "Signups",
    href: "/signups",
    icon: ["fal", "clipboard-list-check"] as IconProp,
  },
  {
    name: "Affiliates",
    href: "/affiliates",
    icon: ["fal", "id-badge"] as IconProp,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: ["fal", "sliders-v-square"] as IconProp,
  },
];

const DashboardLayout = ({
  children,
  title,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSignout = async () => {
    await Auth.signOut();
    history.push(location.pathname);
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100 payzip-background">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-100">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-7 w-auto rounded-full fill-primary-900"
                  src={Logo}
                  alt="Payzip"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => {
                    let current = false;
                    if (location.pathname === "/") {
                      current = item.href === "/";
                    } else {
                      current =
                        location.pathname.includes(item.href) &&
                        item.href !== "/";
                    }
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          current ? "bg-primary-50" : "hover:bg-primary-200",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md text-primary-1000"
                        )}
                      >
                        <div className="w-8 mr-2 flex justify-center">
                          <FontAwesomeIcon
                            className="flex-shrink-0"
                            icon={item.icon}
                            size="lg"
                          />
                        </div>
                        {item.name}
                      </Link>
                    );
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-primary-100 md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-7 w-auto rounded-full fill-primary-900"
                src={Logo}
                alt="Payzip"
              />
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 space-y-1">
                {navigation.map((item) => {
                  let current = false;
                  if (location.pathname === "/") {
                    current = item.href === "/";
                  } else {
                    current =
                      location.pathname.includes(item.href) &&
                      item.href !== "/";
                  }
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        current ? "bg-primary-50" : "hover:bg-primary-200",
                        "text-primary-1000 group flex items-center px-2 py-2 text-sm rounded-md"
                      )}
                    >
                      <div className="w-8 mr-2 flex justify-center">
                        <FontAwesomeIcon
                          className="flex-shrink-0"
                          icon={item.icon}
                          size="lg"
                        />
                      </div>
                      {item.name}
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-200">
                      <span className="text-sm font-medium leading-none text-gray-900">
                        PZ
                      </span>
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none">
                    <Menu.Item>
                      <span className="block px-4 py-2 text-sm text-gray-900 cursor-pointer" role="button" onClick={handleSignout}>Sign out</span>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900 text-left">
                {title}
              </h1>
            </div>
            <div className="mx-auto px-4 sm:px-6 md:px-8">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
