import React, { useEffect, useState, useCallback } from "react";
import { classNames } from "../../lib/helpers";
import Select from "../Input/Select";
import { Components } from "./components";
import { options } from "./options";
import Checkbox from "../Input/Checkbox";

export default function FilterOption({
  type,
  active,
  label,
  filterKey,
  onChange,
  options: filterOptions,
  subtype,
  selected,
  checked = false,
}: {
  type: filterOptions;
  active: boolean;
  label: string;
  filterKey: string;
  onChange: (value: onChangeType) => void;
  options?: option[];
  subtype?: subtypes;
  selected: selectedFilterDetails | undefined;
  checked: boolean;
}) {
  const [showFilter, setShowFilter] = useState(checked);
  const [selectedOption, setSelectedOption] = useState(
    selected && selected.key
      ? options[type].find((op: filterObject) => op.key === selected?.key)
      : options[type][0]
  );

  const [CurrentComponent, setCurrentComponent] = useState<
    React.FunctionComponent<filterProps> | any
  >();

  const handleChange = useCallback(
    (target: { value: any }) => {
      if (selectedOption) {
        if (["between"].includes(selectedOption.key)) {
          console.log(target);
          console.log(target.value);
          onChange({
            key: filterKey,
            options: {
              key: selectedOption.key as OptionKeys,
              value: {
                start: target.value.start,
                end: target.value.end,
              },
            },
          });
        } else {
          if (!target.value) {
            onChange({
              key: filterKey,
            });
            return;
          }

          onChange({
            key: filterKey,
            options: {
              key: selectedOption?.key as OptionKeys,
              value: target.value,
            },
          });
        }
      }

      return;
    },
    [filterKey, onChange, selectedOption]
  );

  useEffect(() => {
    const componentKey = subtype || selectedOption?.key;
    if (Components[type] && componentKey) {
      setCurrentComponent(
        React.createElement(
          Components[type][
            componentKey as OptionKeys
          ] as React.FunctionComponent<filterProps>,
          {
            onChange: handleChange,
            optionKey: selectedOption?.key,
            options: filterOptions,
            value: selected?.value || "",
          }
        )
      );
    }
  }, [
    filterOptions,
    handleChange,
    selected?.value,
    selectedOption?.key,
    subtype,
    type,
  ]);

  useEffect(() => {
    setShowFilter(checked);
  }, [checked]);

  const setFilterOption = (option: filterObject) => {
    setSelectedOption(option);
  };

  const handleShowingFilter = (checked: boolean) => {
    setShowFilter(checked);

    if (!checked) {
      onChange({
        key: filterKey,
      });
    }
  };

  return (
    <div>
      <div
        className={classNames(
          active ? "bg-gray-100 text-gray-900" : "text-gray-900",
          "relative flex items-start p-2"
        )}
      >
        <Checkbox
          value={filterKey}
          checked={showFilter}
          label={label}
          onChange={(event: any) => handleShowingFilter(event.target.checked)}
        />
      </div>
      {showFilter && (
        <div className="px-2 py-2 bg-gray-100">
          {!subtype && (
            <Select
              onChange={(option: filterObject | selectOptions) =>
                setFilterOption(option as filterObject)
              }
              options={options[type]}
              defaultValue={selectedOption}
            />
          )}
          {CurrentComponent}
        </div>
      )}
    </div>
  );
}
