import { useHistory } from 'react-router'
import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const OnboardingIndex = ({ url }: { url?: string }) => {
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {},
    },
    {
      key: "pending",
      label: "Pending",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: [
            "stripePending",
            "memberPending",
            "invoicePending",
            "paymentPending",
            "payoutPending",
          ],
        },
      },
    },
    {
      key: "trial",
      label: "Trial",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["trialPending"],
        },
      },
    },
    {
      key: "abandoned",
      label: "Abandoned",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["abandoned"]
        }
      }
    }
  ];
  const defaultFilter = "pending";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);
  const history = useHistory();

  const [tableData, headers, count] = useApiHook("/onboarding", routeQuery);

  const handleRowClick = (row: any) => {
    history.push("/clubs/" + row.clubId);
  };

  return (
    <div>
      <TableLayout
        rows={tableData}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        defaultFilter={defaultFilter}
        tabs={tabs}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default OnboardingIndex;
