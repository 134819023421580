import React from "react";
import moment from "moment";
import { useHistory } from "react-router";

import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const PayoutsIndex = ({ url }: { url?: string }) => {
  const history = useHistory();
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {},
    },
    {
      key: "last-12-weeks",
      label: "Last 12 weeks",
      filterPreset: {
        arrivalDate: {
          key: "gt" as OptionKeys,
          value: moment().subtract(12, "weeks").format("YYYY-MM-DD"),
        },
      },
    },
  ];
  const defaultFilter = "last-12-weeks";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const [tableData, headers, count] = useApiHook("/payouts", routeQuery);

  const handleRowClick = (row: any) => {
    history.push("/payouts/" + row.statementDescriptor);
  };

  return (
    <TableLayout
      rows={tableData}
      headers={headers}
      count={count}
      onRowClick={handleRowClick}
      handleFiltering={generateUrl}
      defaultFilter={defaultFilter}
      tabs={tabs}
    />
  );
};

export default PayoutsIndex;
