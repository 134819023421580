import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

function DateRangeOption({ onChange, optionKey, value }: filterProps) {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const [labels] = React.useState<labelObject>({
    between: {
      label: "Between",
      id: "between",
    },
  });

  useEffect(() => {
    if (value.start && moment(value.start, "YYYY-MM-DD").isValid()) {
      setStartDate(moment(value.start, "YYYY-MM-DD").toDate());
    }
    if (value.end && moment(value.end, "YYYY-MM-DD").isValid()) {
      setEndDate(moment(value.end, "YYYY-MM-DD").toDate());
    }
  }, [value.start, value.end]);

  const handleStartDateChange = (date: Date): void => {
    setStartDate(date);

    if (startDate && endDate) {
      onChange({
        value: {
          start: moment(date).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        },
      });
    }
  };

  const handleEndDateChange = (date: Date): void => {
    setEndDate(date);

    if (startDate && endDate) {
      onChange({
        value: {
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(date).format("YYYY-MM-DD"),
        },
      });
    }
  };
  return (
    <div className="pt-2">
      {optionKey && (
        <label htmlFor={labels[optionKey]?.id} className="sr-only">
          {labels[optionKey]?.label}
        </label>
      )}
      <div className="grid grid-cols-2 gap-2">
        <div>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => handleStartDateChange(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            popperPlacement="auto"
          />
        </div>
        <div>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => handleEndDateChange(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            popperPlacement="auto"
          />
        </div>
      </div>
    </div>
  );
}

export default DateRangeOption;
