const UnlinkAffiliate = ({}) => {
  // const [affiliate, setCurrentAffiliate] = useState<
  //   selectOptions | undefined
  // >();
  // const [affiliates] = useState<any[]>([
  //   {
  //     id: 0,
  //     name: "None",
  //     email: "",
  //   },
  //   {
  //     id: 1,
  //     name: "Daniel Neenan",
  //     email: "daniel@payzip.co.uk",
  //   },
  //   {
  //     id: 2,
  //     name: "Luke Berry",
  //     email: "luke@payzip.co.uk",
  //   },
  //   {
  //     id: 3,
  //     name: "Jay Thomas",
  //     email: "jay@payzip.co.uk",
  //   },
  //   {
  //     id: 3,
  //     name: "Simon Hodgkinson",
  //     email: "simon@payzip.co.uk",
  //   },
  // ]);

  // const handleChange = (value: any) => {
  //   setCurrentAffiliate(value);
  //   setFormValue({
  //     name: "affiliate",
  //     value: value,
  //   });
  // };

  return <p>Yo, you sure?</p>;
};

export default UnlinkAffiliate;
