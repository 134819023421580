import AddCreditsConfirmation from "../Components/Confirmation/AddCredits";
import AddFeeConfirmation from "../Components/Confirmation/AddFee";
import ChangeAffiliateModal from "../Components/Confirmation/ChangeAffiliate";
import ConnectAccountConfirmation from "../Components/Confirmation/ConnectAccount";
import DisconnectAccountConfirmation from "../Components/Confirmation/DisconnectAccount";
import CreateFeeConfirmation from "../Components/Confirmation/CreateFee";
import CreateCreditConfirmation from "../Components/Confirmation/CreateNewCredit";
import InviteNewUser from "../Components/Confirmation/InviteNewUser";
import TurnOnDemo from "../Components/Confirmation/TurnOnDemo";
import TurnOffDemo from "../Components/Confirmation/TurnOffDemo";
import CreateDemoPayout from "../Components/Confirmation/CreateDemoPayout";
import UnlinkAffiliate from "../Components/Confirmation/UnlinkAffiliate";

export const addCreditConfirmation = (
  callback: Function
): confirmationModel => ({
  title: "Add credit",
  body: AddCreditsConfirmation,
  callback,
  button: {
    submit: {
      text: "Add credit",
    },
  },
});
export const updateFeeConfirmation = (
  callback: Function
): confirmationModel => ({
  title: "Change fee",
  body: AddFeeConfirmation,
  callback,
  button: {
    submit: {
      text: "Save",
    },
  },
});

export const assignAffiliateConfirmation = {
  title: "Assign affiliate",
  body: ChangeAffiliateModal,
  callback: (value: any) => console.log("Added affiliate", value),
  button: {
    submit: {
      text: "Link affiliate",
    },
  },
};

export const unlinkAffiliate = {
  title: "Unlink affiliate",
  body: UnlinkAffiliate,
  callback: (value: any) => console.log("Added affiliate", value),
  button: {
    submit: {
      text: "Unlink affiliate",
    },
  },
};

export const createNewFee = (callback: Function): confirmationModel => ({
  title: "Add fee",
  body: CreateFeeConfirmation,
  callback,
  button: {
    submit: {
      text: "Add fee",
    },
  },
});
export const inviteNewUser = {
  title: "Add user",
  body: InviteNewUser,
  callback: (value: any) => console.log("Inviting new user", value),
  button: {
    submit: {
      text: "Add user",
    },
  },
};
export const createCredit = (callback: Function): confirmationModel => ({
  title: "Add credit",
  body: CreateCreditConfirmation,
  callback,
  button: {
    submit: {
      text: "Add credit",
    },
  },
});

export const connectAccount = (callback: Function): confirmationModel => ({
  title: "Connect to club",
  body: ConnectAccountConfirmation,
  callback,
  button: {
    submit: {
      text: "Connect",
    },
  },
});

export const disconnectAccount = (callback: Function): confirmationModel => ({
  title: "Disconnect from club",
  body: DisconnectAccountConfirmation,
  callback,
  button: {
    submit: {
      text: "Disconnect",
    },
  },
});

export const turnOnDemo = (callback: Function): confirmationModel => ({
  title: "Turn on demo",
  body: TurnOnDemo,
  callback,
  button: {
    submit: {
      text: "Turn on",
    },
  },
});

export const turnOffDemo = (callback: Function): confirmationModel => ({
  title: "Turn off demo",
  body: TurnOffDemo,
  callback,
  button: {
    submit: {
      text: "Turn off",
    },
  },
});

export const createDemoPayout = (callback: Function): confirmationModel => ({
  title: "Create demo payout",
  body: CreateDemoPayout,
  callback,
  button: {
    submit: {
      text: "Create payout",
    },
  },
});
