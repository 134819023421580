import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import Filter from "../Components/Filter";
import Table from "../Components/Table";
import Tabs from "../Components/Tabs";

const TableLayout = ({
  rows,
  headers,
  count,
  tabs = [],
  handleFiltering,
  defaultFilter,
  overrideFilter,
  onRowClick,
}: {
  tabs?: tabObject[] | [];
  handleFiltering: (page: number, value: selectedFilter) => void;
  overrideFilter?: selectedFilter
} & tableProps) => {
  const defaultFilterRef = useRef(defaultFilter);
  const tabRef = useRef(tabs);
  const location = useLocation();
  const [page, setPage] = useState(1);

  const [presetFilter, setPresetFilter] = useState<selectedFilter>({});
  const handleUpdatingFilter = (page: number, value: selectedFilter) => {
    setPage(page);
    setPresetFilter(value);
    if (handleFiltering) {
      handleFiltering(page, value);
    }
  };

  useEffect(() => {
    if (defaultFilterRef.current) {
      const tab = tabRef.current.find(
        (tab) => tab.key === defaultFilterRef.current
      );
      if (tab?.filterPreset) {
        setPresetFilter(tab.filterPreset);
      }
    }
  }, []);

  const handlePageChange = (page: number) => {
    setPage(page);
    if (handleFiltering) {
      handleFiltering(page, presetFilter);
    }
  };

  useEffect(() => {
    if (overrideFilter) {
      console.log('overridididididid')
      setPresetFilter(overrideFilter)
    }
  }, [])

  useEffect(() => {
    if (location.hash) {
      setPresetFilter({});
    }
  }, [location.hash]);

  return (
    <div>
      <div className="mx-auto">
        <Tabs
          tabs={tabs}
          updateFilter={(filter) => handleUpdatingFilter(1, filter)}
          currentFilter={presetFilter}
        >
          <Filter
            presetFilter={presetFilter}
            headers={headers}
            fields={headers.filter(
              (header) =>
                header.type &&
                header.type !== "search" &&
                (header.filterVisible === undefined || header.filterVisible)
            )}
            onDone={(filter) => handleUpdatingFilter(1, filter)}
            onClear={(value) => {
              handleUpdatingFilter(1, value);
            }}
            data={rows}
            location={location.pathname}
          />
        </Tabs>
      </div>
      <Table
        rows={rows}
        headers={headers.filter(
          (header) => header.tableVisible === undefined || header.tableVisible
        )}
        count={count}
        onRowClick={onRowClick}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TableLayout;
