import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import ConfirmationModal from "../Components/Confirmation";
import Modal from "../Components/Modal";
import Stats from "../Components/Stats";

import TableLayout from "../Layouts/TableLayout";
import { generateQuery } from "../lib/helpers";
import { useApiHook, useAxiosInstance } from "../lib/hooks";
import { updateFeeConfirmation } from "../lib/modals";

const AffiliatePage = ({ match }: RouteProp) => {
  const location = useLocation();
  const history = useHistory();
  const instance = useAxiosInstance();
  const [apiUrl, setApiUrl] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState<confirmationModel>();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [fees, setFees] = useState([]);

  const [routeQuery, setRouteQuery] = useState("?page=1");

  const [tableData, headers, count, stats] = useApiHook(apiUrl, routeQuery);
  const [sortedTableData, setSortedTableData] = useState([])

  const generateUrl = (page: number, filter: selectedFilter) => {
    const filterQuery = generateQuery(page, filter);

    setRouteQuery(filterQuery);
  };

  const [affiliate, setAffiliate] = useState<AffiliateModel>();

  useEffect(() => {
    instance.get(`/affiliates/${match.params.id}`).then(({ data }) => {
      setAffiliate(data);
    });
  }, [match.params.id, instance]);

  useEffect(() => {
    let freeTableData = JSON.parse(JSON.stringify(tableData))
    switch (location.hash) {
      case "#payments":
      case "":
        freeTableData.sort((a: any, b: any) => b.paymentDate.localeCompare(a.paymentDate))
        setSortedTableData(freeTableData)
        break;
      default:
        setSortedTableData(freeTableData)
        break;
    }
  }, [tableData]);

  const handleConfirmationSubmit = (value: any) => {
    if (confirmation) {
      setOpenConfirmation(false);
      confirmation.callback(value);
    }
  };

  const openConfirmationModal = (modal: confirmationModel) => {
    setConfirmation(modal);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    // On hash change, request new table headers,data, count
    if (match && match.params.id) {
      setRouteQuery("?page=1");
      switch (location.hash) {
        case "#payouts":
          setApiUrl(`/affiliates/${match.params.id}/payouts`);
          break;
        case "#clubs":
          setApiUrl(`/affiliates/${match.params.id}/clubs`);
          break;
        default:
          setApiUrl(`/affiliates/${match.params.id}/payments`);
          break;
      }
    }
  }, [location.hash, match]);

  useEffect(() => {
    instance.get("/fees?affiliate=1").then(({ data }) => {
      setFees(data);
    });
  }, [instance]);

  const setFee = ({ fee }: { fee: any }) => {
    instance
      .post(`/affiliates/${match.params.id}/fees`, fee)
      .then(({ data }: { data: AffiliateModel }) => {
        setAffiliate(data);
      });
  };

  const onRowClick = (row: any) => {
    history.push(`/clubs/${row.clubId}`)
  }

  if (affiliate) {
    return (
      <div>
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 text-left">
            {affiliate.name}
          </h1>
          <div className="flex space-x-4 items-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 shadow text-xs font-medium rounded text-gray-900 bg-white focus:outline-none"
              onClick={() => setOpen(true)}
            >
              Edit
            </button>

            <button
              type="button"
              className="inline-flex items-center px-4 py-2 text-xs font-medium rounded shadow text-white bg-primary-100 text-primary-1000 focus:outline-none"
            >
              Control
            </button>
          </div>
        </div>
        <Stats stats={stats} />
        <TableLayout
          rows={sortedTableData}
          headers={headers}
          count={count}
          handleFiltering={generateUrl}
          onRowClick={onRowClick}
          tabs={[
            {
              key: "payments",
              label: "Payments",
              hash: "#payments",
            },
            {
              key: "payouts",
              label: "Payouts",
              hash: "#payouts",
            },
            {
              key: "clubs",
              label: "Clubs",
              hash: "#clubs",
            },
          ]}
        />
        <Modal open={open} setOpen={setOpen}>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {affiliate.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {"<"}
              {affiliate.email}
              {">"} / {affiliate.mobile}
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Fee</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center space-x-2">
                  <p>{affiliate.fee}</p>
                  <div
                    onClick={() =>
                      openConfirmationModal(updateFeeConfirmation(setFee))
                    }
                    className="text-primary-400 underline cursor-pointer"
                  >
                    Change
                  </div>
                </dd>
              </div>
              {affiliate.review && (
                <div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Date approved
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {affiliate.review.date}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Approved by
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {affiliate.review.person}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Reason
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {affiliate.review.reason}
                    </dd>
                  </div>
                </div>
              )}
            </dl>
          </div>
          {confirmation && (
            <ConfirmationModal
              title={confirmation.title}
              callback={handleConfirmationSubmit}
              open={openConfirmation}
              setOpen={setOpenConfirmation}
              props={{
                ...confirmation.props,
                currentFee: affiliate.fee,
                fees,
              }}
              modal={confirmation}
            />
          )}
        </Modal>
      </div>
    );
  }

  return <React.Fragment />;
};

export default AffiliatePage;
