import React, { useEffect, useState } from "react";
import { useApiHook } from "../../lib/hooks";
import Select from "../Input/Select";

const ChangeAffiliateModal = ({
  organisation,
  setFormValue,
}: {
  organisation: any;
  setFormValue: Function;
}) => {
  const [affiliate, setCurrentAffiliate] = useState<
    selectOptions | undefined
  >();
  const [affiliates] = useState<any[]>([]);
  const [data, headers, count] = useApiHook("/affiliates");

  const handleChange = (value: any) => {
    setCurrentAffiliate(value);
    setFormValue({
      name: "affiliate",
      value: value,
    });
  };

  return (
    <Select
      label="Select affiliate"
      onChange={() => {}}
      options={[{ key: "Hockey Wales", name: "Hockey Wales", value: "Hockey Wales" }]}
      defaultValue={affiliate}
    />
  );
};

export default ChangeAffiliateModal;
