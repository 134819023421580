import React, { useEffect, useState } from "react";
import { classNames, handleDataFormat } from "../../lib/helpers";
import Pagination from "../Pagination";

export default function Table({
  rows,
  headers,
  count,
  onRowClick = () => {},
  page = 1,
  onPageChange = () => {},
  hidePagination = false,
  shadow = true,
  rounded = true,
}: tableProps) {
  const [data, setData] = useState(rows);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={classNames(
              shadow ? "shadow" : "",
              rounded ? "sm:rounded-lg" : "",
              "overflow-hidden"
            )}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {headers
                    .filter((header) => !header.parent)
                    .map((header) => {
                      return (
                        <th
                          key={header.key + "-header"}
                          scope="col"
                          className={
                            "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" +
                            (header.type === "number" ? " text-right" : "")
                          }
                        >
                          {header.label}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.map((row, index) => (
                  <tr
                    key={"row-" + index}
                    className={classNames(
                      index % 2 === 0 ? "bg-white" : "bg-gray-50",
                      onRowClick ? "cursor-pointer" : "",
                      "row-hover-effect"
                    )}
                  >
                    {headers.map((header) => {
                      let child: field | undefined;
                      if (header.child) {
                        child = headers.find(({ key }) => {
                          return key === header.child;
                        });
                      }
                      return (
                        <td
                          onClick={() => onRowClick(row)}
                          key={header.key + "-row-" + index}
                          className={classNames(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            header.parent ? "hidden" : "",
                            header.type === "number"
                              ? "text-right"
                              : "text-left"
                          )}
                        >
                          <div>
                            {header.format &&
                            header.format == "onlineStatus" ? (
                              row[header.key] == "online" ? (
                                <span className="text-green-500">Online</span>
                              ) : (
                                <span className="text-red-500">Offline</span>
                              )
                            ) : header.format ? (
                              handleDataFormat(row[header.key], header.format)
                            ) : (
                              row[header.key]
                            )}
                          </div>
                          {child ? (
                            <div className="text-sm font-normal text-gray-500">
                              {row[child.key]}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            {!hidePagination && (
              <Pagination
                page={page}
                count={count}
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
