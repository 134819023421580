import React from "react";
import Stats from "../Components/Stats";
import moment from "moment";
import { useHistory } from 'react-router-dom';

import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const PaymentsIndex = ({ url }: { url?: string }) => {
  const history = useHistory();
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
    {
      key: "today",
      label: "Today",
      filterPreset: {
        paymentDate: {
          key: "equal" as OptionKeys,
          value: moment().format("YYYY-MM-DD"),
        },
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
    {
      key: "last-7-days",
      label: "Last 7 days",
      filterPreset: {
        paymentDate: {
          key: "between" as OptionKeys,
          value: {
            start: moment().subtract(1, "week").format("YYYY-MM-DD"),
            end: moment().subtract(1, "day").format("YYYY-MM-DD"),
          },
        },
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
  ];
  const defaultFilter = "today";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const [tableData, headers, count, stats] = useApiHook(
    "/transactions",
    routeQuery
  );

  const handleRowClick = (row: any) => {
    history.push("/clubs/" + row.clubId)
  }

  return (
    <div>
      <Stats stats={stats} />
      <TableLayout
        rows={tableData.sort((a: any, b: any) => b.paymentDate.localeCompare(a.paymentDate))}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        defaultFilter={defaultFilter}
        tabs={tabs}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default PaymentsIndex;
