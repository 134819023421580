import React, { useEffect, useState } from "react";
import Select from "../Input/Select";

const AddCreditsConfirmation = ({
  organisation,
  vouchers,
  setFormValue,
}: {
  organisation: any;
  vouchers: { name: string; id: number }[];
  setFormValue: Function;
}) => {
  const [allVouchers] = useState(
    vouchers.map(
      (voucher: { name: string; id: number }): selectOptions => ({
        key: voucher.name,
        name: voucher.name,
        value: voucher,
      })
    )
  );

  const [credit] = useState<selectOptions | undefined>(allVouchers[0]);

  useEffect(() => {
    setFormValue({
      name: "credit",
      value: allVouchers[0],
    });
  }, [allVouchers, setFormValue]);

  const handleChange = (value: selectOptions) => {
    setFormValue({
      name: "credit",
      value: value,
    });
  };

  return (
    <Select
      label="Select credit"
      options={allVouchers}
      onChange={(value: filterObject | selectOptions) =>
        handleChange(value as selectOptions)
      }
      defaultValue={credit}
    />
  );
};

export default AddCreditsConfirmation;
