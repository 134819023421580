import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../lib/helpers";

export default function Single({
  options = [],
  onChange,
  value = "",
}: filterProps) {
  const [selected, setSelectedOption] = useState(
    options.find((option) => option.value === value)
  );

  const handleSettingValue = (option: any) => {
    onChange({ value: option.value });
    setSelectedOption(option);
  };

  return (
    <RadioGroup value={selected} onChange={handleSettingValue}>
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="space-y-2">
        {options.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.label}
            value={setting}
            className="relative flex cursor-pointer focus:outline-none"
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-primary-400 border-transparent"
                      : "bg-white border-gray-300",
                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className="block text-sm text-gray-900 font-light"
                  >
                    {setting.label}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
