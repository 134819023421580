import React, { useState } from "react";
import Stats from "../Components/Stats";

import TableLayout from "../Layouts/TableLayout";
import { generateQuery } from "../lib/helpers";
import { useApiHook } from "../lib/hooks";

const PayeePayoutPage = ({ match }: RouteProp) => {
  // match.params.id in this case is the statementDescriptor for the payout
  const [routeQuery, setRouteQuery] = useState("?page=1");

  const [tableData, headers, count, stats] = useApiHook(
    `/payouts/${match.params.id}/payees/${match.params.payeeId}`,
    routeQuery
  );

  const generateUrl = (page: number, filter: selectedFilter) => {
    const filterQuery = generateQuery(page, filter);

    setRouteQuery(filterQuery);
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 text-left">
        {match.params.id}: {tableData ? `${tableData[0]?.clubName}` : ""}
      </h1>
      <Stats stats={stats} />
      <TableLayout
        rows={tableData}
        headers={headers}
        count={tableData.length}
        handleFiltering={generateUrl}
      />
    </div>
  );
};

export default PayeePayoutPage;
