import NumberSingle from "./Number/Single";
import DateSingle from "./Date/Single";
import DateRange from "./Date/Range";
import SelectSingle from "./Select/Single";
import SelectMultiple from "./Select/Multiple";

export const Components: {
  [key in filterOptions]: {
    [key in OptionKeys]?: React.FunctionComponent<filterProps>;
  };
} = {
  number: {
    equal: NumberSingle,
    gt: NumberSingle,
    lt: NumberSingle,
    between: DateRange,
  },
  date: {
    equal: DateSingle,
    gt: DateSingle,
    lt: DateSingle,
    between: DateRange,
  },
  select: {
    single: SelectSingle,
    multiple: SelectMultiple,
  },
  search: {},
};
