import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import Modal from "../Components/Modal";
import Stats from "../Components/Stats";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import TableLayout from "../Layouts/TableLayout";
import { classNames, formatMoney, generateQuery } from "../lib/helpers";
import ConfirmationModal from "../Components/Confirmation";
import {
  addCreditConfirmation,
  assignAffiliateConfirmation,
  updateFeeConfirmation,
  connectAccount,
  disconnectAccount,
  unlinkAffiliate,
} from "../lib/modals";
import { useApiHook, useAxiosInstance, useRouteQueryHook } from "../lib/hooks";

const ClubPage = ({ match }: RouteProp) => {
  const overrideFilter = [
    {
      key: "active",
      label: "Active",
      filterPreset: {
        paymentDate: {
          key: "gt" as OptionKeys,
          value: moment().subtract(1, "year").format("YYYY-MM-DD"),
        },
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
  ];

  const location = useLocation();
  const history = useHistory();
  const instance = useAxiosInstance();
  const [apiUrl, setApiUrl] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState<confirmationModel>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [vouchers, setVouchers] = useState([]);

  const [showVouchers, setShowVouchers] = useState(false);
  const [fees, setFees] = useState(false);

  const [routeQuery, setRouteQuery] = useRouteQueryHook(
    overrideFilter,
    "active"
  );

  const [tableData, headers, count, stats] = useApiHook(apiUrl, routeQuery);
  const [sortedTableData, setSortedTableData] = useState([]);

  // const generateUrl = (page: number, filter: selectedFilter) => {
  //   const filterQuery = generateQuery(page, filter);

  //   // setRouteQuery(filterQuery);
  // };

  const [organisation, setOrganisation] = useState<
    OrganisationModel | undefined
  >();

  useEffect(() => {
    instance.get(`/clubs/${match.params.id}`).then(({ data }) => {
      setOrganisation(data);
    });
  }, [match.params.id, instance]);

  useEffect(() => {
    let freeTableData = JSON.parse(JSON.stringify(tableData));
    switch (location.hash) {
      case "#payments":
      case "":
        freeTableData.sort((a: any, b: any) =>
          b.paymentDate.localeCompare(a.paymentDate)
        );
        setSortedTableData(freeTableData);
        break;
      default:
        setSortedTableData(freeTableData);
        break;
    }
  }, [tableData]);

  const handleConfirmationSubmit = (value: any) => {
    if (confirmation) {
      setOpenConfirmation(false);
      confirmation.callback(value);
    }
  };

  const openConfirmationModal = (modal: confirmationModel) => {
    setConfirmation(modal);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    // On hash change, request new table headers,data, count
    if (match && match.params.id) {
      // setRouteQuery("?page=1");
      switch (location.hash) {
        case "#payouts":
          setApiUrl(`/clubs/${match.params.id}/payouts`);
          break;
        case "#admins":
          setApiUrl(`/clubs/${match.params.id}/admins`);
          break;
        default:
          setApiUrl(`/clubs/${match.params.id}/payments`);
          break;
      }
    }
  }, [location.hash, match]);

  useEffect(() => {
    instance.get("/vouchers").then(({ data }) => {
      setVouchers(data);
    });
  }, [instance]);

  useEffect(() => {
    instance.get("/fees?affiliate=0").then(({ data }) => {
      setFees(data);
    });
  }, [instance]);

  const setVoucher = (voucher: any) => {
    instance
      .post(`/clubs/${match.params.id}/vouchers`, voucher.credit.value)
      .then(({ data }: { data: OrganisationModel }) => {
        setOrganisation(data);
      });
  };

  const setFee = ({ fee }: { fee: any }) => {
    instance
      .post(`/clubs/${match.params.id}/fees`, fee)
      .then(({ data }: { data: OrganisationModel }) => {
        setOrganisation(data);
      });
  };

  const abandonClub = () => {
    instance
      .post(`/clubs/${match.params.id}/abandon`)
      .then(({ data }: { data: OrganisationModel }) => {
        setOrganisation(data);
      });
  };

  const handleRowClick = (row: any) => {
    if (location.hash == "#payouts") {
      if (organisation) {
        history.push(
          `/payouts/${row.statementDescriptor}/payees/${organisation.payeeId}`
        );
      }
    }
  };

  const connect = () => {
    instance
      .post(`/clubs/${match.params.id}/connect`)
      .then(({ data }: { data: OrganisationModel }) => {
        setOrganisation(data);
      })
      .catch((e: Error) => {
        alert(`Unable to connect to account: ${e.message}`);
      });
  };

  const disconnect = () => {
    instance
      .post(`/clubs/${match.params.id}/disconnect`)
      .then(({ data }: { data: OrganisationModel }) => {
        setOrganisation(data);
      })
      .catch((e: Error) => {
        alert(`Unable to disconnect from account: ${e.message}`);
      });
  };

  if (organisation) {
    return (
      <div>
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 text-left">
            {organisation.name}
          </h1>
          <div className="flex space-x-8 items-center">
            <div>
              <span className="text-gray-500">Card %</span>{" "}
              <span className="text-gray-900 font-medium">
                {organisation.cardPercentage}%
              </span>
            </div>
            <div>
              <span className="text-gray-500">Avg TTP</span>{" "}
              <span className="text-gray-900 font-medium">
                {organisation.averageTimeToPay}
              </span>
            </div>
            <div>
              <span className="text-gray-500">Total owed</span>{" "}
              <span className="text-gray-900 font-medium">
                {formatMoney(organisation.totalOwed / 100)}
              </span>
            </div>
            <div className="space-x-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 shadow text-xs font-medium rounded text-gray-900 bg-white focus:outline-none"
                onClick={() => setOpen(true)}
              >
                Edit
              </button>

              {organisation.connected ? (
                <span
                  className="inline-flex items-center px-4 py-2 text-xs font-medium rounded shadow text-white bg-red-500 text-primary-1000 focus:outline-none cursor-pointer"
                  onClick={() =>
                    openConfirmationModal(disconnectAccount(disconnect))
                  }
                >
                  Disconnect
                </span>
              ) : (
                <span
                  className="inline-flex items-center px-4 py-2 text-xs font-medium rounded shadow text-white bg-primary-100 text-primary-1000 focus:outline-none cursor-pointer"
                  onClick={() => openConfirmationModal(connectAccount(connect))}
                >
                  Connect
                </span>
              )}
            </div>
          </div>
        </div>
        <Stats stats={stats} />
        <TableLayout
          rows={sortedTableData}
          headers={headers}
          count={count}
          handleFiltering={setRouteQuery}
          overrideFilter={overrideFilter[0].filterPreset}
          onRowClick={handleRowClick}
          tabs={[
            {
              key: "payments",
              label: "Payments",
              hash: "#payments",
            },
            {
              key: "payouts",
              label: "Payouts",
              hash: "#payouts",
            },
            {
              key: "admins",
              label: "Admins",
              hash: "#admins",
            },
          ]}
        />
        <Modal open={open} setOpen={setOpen}>
          <div className="flex justify-between items-center">
            <div className="flex">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {organisation.name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {organisation.primaryUser.name}{" "}
                  {`<${organisation.primaryUser.email}>`}
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="px-4 py-5 sm:px-6">
                <button
                  onClick={abandonClub}
                  className="inline-flex justify-center rounded-md shadow px-4 py-2 bg-white text-sm text-gray-900 focus:outline-none"
                >
                  {organisation.abandoned ? "Adopt" : "Abandon"}
                </button>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Fee</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {organisation.fee}{" "}
                  <span
                    onClick={() =>
                      openConfirmationModal(updateFeeConfirmation(setFee))
                    }
                    className="text-primary-400 underline cursor-pointer"
                  >
                    Change
                  </span>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Credit</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-y-2">
                  <div
                    onClick={() => setShowVouchers(!showVouchers)}
                    className="flex justify-between cursor-pointer"
                  >
                    <div>
                      {formatMoney(
                        organisation.vouchers.reduce(
                          (acc, b) => acc + b.remaining / 100,
                          0
                        )
                      )}
                      /
                      {formatMoney(
                        organisation.vouchers.reduce(
                          (acc, b) => acc + b.volume / 100,
                          0
                        )
                      )}{" "}
                      ({organisation.vouchers.length})
                    </div>
                    {showVouchers ? (
                      <ChevronDownIcon className="w-4" />
                    ) : (
                      <ChevronRightIcon className="w-4" />
                    )}
                  </div>
                  <div
                    className={classNames(
                      showVouchers ? "" : "hidden",
                      "space-y-2"
                    )}
                  >
                    {organisation.vouchers.map((voucher, index) => {
                      return <div key={"voucher-" + index}>{voucher.name}</div>;
                    })}
                    <div>
                      <span
                        onClick={() =>
                          openConfirmationModal(
                            addCreditConfirmation(setVoucher)
                          )
                        }
                        className="underline text-primary-400 cursor-pointer"
                      >
                        Add credit
                      </span>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Affiliate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {!organisation.affiliate ? (
                    <span
                      onClick={() =>
                        openConfirmationModal(assignAffiliateConfirmation)
                      }
                      className="underline text-primary-400 cursor-pointer"
                    >
                      Link
                    </span>
                  ) : (
                    <div>
                      {organisation.affiliate?.user?.name} {"<"}
                      {organisation.affiliate?.user?.email}
                      {">"}
                      <span
                        onClick={() => openConfirmationModal(unlinkAffiliate)}
                        className="underline text-primary-400 cursor-pointer"
                      >
                        Unlink
                      </span>
                    </div>
                    // {organisation.affiliate.user.name} <{organisation.affiliate.user.email}>
                    // <span
                    //   onClick={() =>
                    //     openConfirmationModal(assignAffiliateConfirmation)
                    //   }
                    //   className="underline text-primary-400 cursor-pointer"
                    // >
                    //   Ulink
                    // </span>
                  )}
                </dd>
              </div>
              {organisation.review && (
                <div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Date approved
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {organisation.review.date}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Approved by
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {organisation.review.person}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Reason
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {organisation.review.reason}
                    </dd>
                  </div>
                </div>
              )}
            </dl>
          </div>
        </Modal>
        {confirmation && (
          <ConfirmationModal
            title={confirmation.title}
            callback={handleConfirmationSubmit}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            props={{
              ...confirmation.props,
              organisation,
              currentFee: organisation.fee,
              fees,
              vouchers,
            }}
            modal={confirmation}
          />
        )}
      </div>
    );
  }
  return <React.Fragment />;
};

export default ClubPage;
