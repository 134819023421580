export const options: filter = {
  number: [
    {
      id: 1,
      name: "equal to",
      key: "equal" as OptionKeys,
    },
    {
      id: 2,
      name: "greater than",
      key: "gt" as OptionKeys,
    },
    {
      id: 3,
      name: "less than",
      key: "lt" as OptionKeys,
    },
    {
      id: 4,
      name: "between",
      key: "between" as OptionKeys,
    },
  ],
  date: [
    {
      id: 1,
      name: "equal to",
      key: "equal" as OptionKeys,
    },
    {
      id: 2,
      name: "less than",
      key: "lt" as OptionKeys,
    },
    {
      id: 3,
      name: "greater than",
      key: "gt" as OptionKeys,
    },
    {
      id: 3,
      name: "between",
      key: "between" as OptionKeys,
    },
  ],
  select: [
    {
      id: 1,
      name: "equal to",
      key: "equal" as OptionKeys,
    },
  ],
  search: [],
};
