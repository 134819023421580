import React, { useState } from "react";
import Input from "../Input";

const CreateCreditConfirmation = ({
  setFormValue,
}: {
  organisation: any;
  setFormValue: Function;
}) => {
  const [name, setFee] = useState("");
  const [value, setValue] = useState("");

  return (
    <div className="space-y-4">
      <Input
        label="Name"
        name="name"
        value={name}
        placeholder="Name"
        onChange={(event: any) => {
          setFee(event.target.value);
          setFormValue({
            name: event.target.name,
            value: event.target.value,
          });
        }}
      />
      <Input
        label="Value"
        name="volume"
        placeholder="£0.00"
        mask="currency"
        value={value}
        onChange={(event: any) => {
          setValue(event.target.value);
          setFormValue({
            name: event.target.name,
            value: event.target.value,
          });
        }}
      />
    </div>
  );
};

export default CreateCreditConfirmation;
