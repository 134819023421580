import React from "react";

const Card = ({
  title,
  action = null,
  secondAction = undefined,
  children,
}: {
  title: string;
  children: any;
  action: { label: string; callback: Function } | null;
  secondAction?: { label: string; callback: Function } | undefined;
}) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="bg-white px-2 py-3 border-b border-gray-200 sm:pl-6 sm:pr-3">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          </div>
          <div className="flex">
            {action ? (
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-1000 bg-primary-100 focus:outline-none"
                  onClick={() => action.callback()}
                >
                  {action.label}
                </button>
              </div>
            ) : (
              ""
            )}
            {secondAction ? (
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-1000 bg-primary-100 focus:outline-none"
                  onClick={() => secondAction.callback()}
                >
                  {secondAction.label}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Card;
