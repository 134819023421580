import React, { useState } from "react";
import { useHistory } from "react-router";
import Stats from "../Components/Stats";

import TableLayout from "../Layouts/TableLayout";
import { generateQuery } from "../lib/helpers";
import { useApiHook } from "../lib/hooks";

const PayoutPage = ({ match }: RouteProp) => {
  // match.params.id in this case is the statementDescriptor for the payout
  const history = useHistory();
  const [routeQuery, setRouteQuery] = useState("?page=1");

  const [tableData, headers, count, stats] = useApiHook(
    `/payouts/${match.params.id}/payees`,
    routeQuery
  );

  const generateUrl = (page: number, filter: selectedFilter) => {
    const filterQuery = generateQuery(page, filter);

    setRouteQuery(filterQuery);
  };

  const handleRowClick = (row: any) => {
    history.push(`/payouts/${match.params.id}/payees/${row.payeeId}`);
    // history.push(`/clubs/${row.payeeId}`);
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 text-left">
        {match.params.id}
      </h1>
      <Stats stats={stats} />
      <TableLayout
        rows={tableData.sort((a: any, b: any) => b.balance - a.balance)}
        headers={headers}
        count={count}
        onRowClick={handleRowClick}
        handleFiltering={generateUrl}
      />
    </div>
  );
};

export default PayoutPage;
