import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import { BrowserRouter as Router } from "react-router-dom";

import {
  faFileInvoice,
  faCreditCard,
  faRocket,
  faTachometerAlt,
  faIdBadge,
  faClipboardListCheck,
  faSlidersVSquare,
} from "@fortawesome/pro-light-svg-icons";

import { faShieldAlt } from "@fortawesome/pro-regular-svg-icons";

import Amplify from "aws-amplify";
import config from "./aws-exports";

Amplify.configure(config);

library.add(
  faTachometerAlt,
  faShieldAlt,
  faIdBadge,
  faClipboardListCheck,
  faSlidersVSquare,
  faRocket,
  faCreditCard,
  faFileInvoice
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
