import React, { useState } from "react";
import Select from "../Input/Select";

const AddFeeConfirmation = ({
  fees,
  currentFee,
  setFormValue,
}: {
  fees: selectOptions[];
  currentFee: string;
  setFormValue: Function;
}) => {
  const [fee] = useState<selectOptions | undefined>(
    fees.find((option) => option.name === currentFee)
  );

  const handleChange = (value: selectOptions) => {
    setFormValue({
      name: "fee",
      value: value,
    });
  };

  return (
    <Select
      label="Select fee"
      options={fees}
      onChange={(value: filterObject | selectOptions) =>
        handleChange(value as selectOptions)
      }
      defaultValue={fee}
    />
  );
};

export default AddFeeConfirmation;
