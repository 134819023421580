import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function SingleDateOption({
  onChange,
  optionKey,
  value,
}: filterProps) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (value && moment(value, "YYYY-MM-DD").isValid()) {
      setDate(moment(value, "YYYY-MM-DD").toDate());
    }
  }, [value]);

  const labels: labelObject = {
    equal: {
      label: "Equal to",
      id: "equalTo",
    },
    lt: {
      label: "Less than",
      id: "lessThan",
    },
    gt: {
      label: "Greater than",
      id: "greaterThan",
    },
  };

  const handleChange = (date: Date): void => {
    onChange({ value: moment(date).format("YYYY-MM-DD") });
    setDate(date);
  };
  return (
    <div className="pt-2">
      <div>
        {optionKey && (
          <label htmlFor={labels[optionKey]?.id} className="sr-only">
            {labels[optionKey]?.label}
          </label>
        )}
        <DatePicker
          selected={date}
          onChange={(date: Date) => handleChange(date)}
          selectsStart
          startDate={date}
          dateFormat="dd/MM/yyyy"
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          popperPlacement="left"
          popperModifiers={[
            {
              name: "flip",
              options: {
                fallbackPlacements: [
                  "bottom",
                  "bottom-end",
                  "bottom-start",
                  "left",
                  "left-end",
                ],
              },
            },
            {
              name: "preventOverflow",
              options: {
                mainAxis: true,
                rootBoundary: "viewport",
                padding: 5,
                tether: false,
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
