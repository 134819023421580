const TurnOnDemo = () => {
  return (
    <div className="space-y-4">
      <p>
        Are you sure you want to turn on demo? It may take up to 5 minutes for
        demo to come online, you will have to refresh this page to see the
        status change.
      </p>
    </div>
  );
};

export default TurnOnDemo;
