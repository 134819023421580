import { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../config";
import { generateQuery } from "./helpers";

export const useAxiosInstance = (
  useInternal: boolean = true,
  apiUrl: string = config.api_url
) => {
  apiUrl = apiUrl + "/internal";

  if (!useInternal) {
    apiUrl = config.api_url || "";
  }

  const apiInstance = useRef(
    axios.create({
      baseURL: apiUrl,
    })
  );

  return apiInstance.current;
};

export const useApiHook = (
  url?: string,
  routeQuery?: string
): [any, field[], number, StatsObject[]] => {
  const apiInstance = useAxiosInstance();
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState<field[]>([]);
  const [stats, setStats] = useState<StatsObject[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (url && routeQuery) {
      apiInstance.get(url + routeQuery).then(({ data }) => {
        setHeaders(data.headers);
        setTableData(data.rows);
        setCount(data.count);
        setStats((currentStats) => (data.stats ? data.stats : currentStats));
      });
    }
  }, [url, routeQuery, apiInstance]);

  return [tableData, headers, count, stats];
};

export const useRouteQueryHook = (
  tabs: tabObject[],
  defaultKey: string
): [string, (page: number, value: selectedFilter) => void] => {
  const tabRef = useRef(tabs);
  const defaultKeyRef = useRef(defaultKey);
  const [routeQuery, setRouteQuery] = useState("");

  const generateUrl = (page: number, filter: selectedFilter) => {
    console.log(page, filter);
    const filterQuery = generateQuery(page, filter);

    setRouteQuery(filterQuery);
  };

  useEffect(() => {
    console.log("running setting route query");
    if (defaultKeyRef.current) {
      const tab = tabRef.current.find(
        (tab) => tab.key === defaultKeyRef.current
      );
      if (tab?.filterPreset) {
        generateUrl(1, tab?.filterPreset);
      } else {
        setRouteQuery("?page=1");
      }
    } else {
      setRouteQuery("?page=1");
    }
  }, []);

  return [routeQuery, generateUrl];
};
