import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import Option from "./Option";
import { SearchIcon } from "@heroicons/react/outline";
import { FilterIcon } from "@heroicons/react/solid";
import { CSVLink } from "react-csv";
import { formatDateForExport } from "../../lib/helpers";

export default function Filter({
  presetFilter = {},
  fields,
  onDone,
  onClear,
  data = [],
  location = "",
  headers = []
}: {
  presetFilter: selectedFilter;
  fields: field[];
  onDone: (value: selectedFilter) => void;
  onClear: (value: selectedFilter) => void;
  data: Array<any>;
  headers: Array<any>;
  location: string;
}) {
  const [selectedFilters, setSelectedFilters] =
    useState<selectedFilter>(presetFilter);
  const [searchValue, setSearchValue] = useState("");

  const formatData = (data: any[]) => {
    let secondData = JSON.parse(JSON.stringify(data))
    const filteredFields = headers.map(field => field.key)
    const filteredNumberFields = fields.filter(field => field.type?.toString() === "number" && field.format?.toString() === "currency").map(field => field.key)
    const filteredDateFields = fields.filter(field => field.type?.toString() === "date" || field.type?.toString() === "dateTime").map(field => field.key)

    secondData.map((row: Object[], rowIdx: number) => {
      Object.keys(row).map(key => {
        if (!filteredFields.includes(key)) {
          delete secondData[rowIdx][key]
        }
        if (filteredNumberFields.includes(key)) {
          secondData[rowIdx][key] = secondData[rowIdx][key] / 100
        }
        if (filteredDateFields.includes(key)) {
          const formatted = formatDateForExport(secondData[rowIdx][key])

          secondData[rowIdx][key] = formatted.date
          secondData[rowIdx].time = formatted.time
        }
      })
    })

    return secondData
  }

  useEffect(() => {
    setSelectedFilters(presetFilter);

    if (presetFilter.search && presetFilter.search.value) {
      setSearchValue(presetFilter.search.value as string);
    } else {
      setSearchValue("");
    }
  }, [presetFilter]);

  const handleSelectedFilter = (value: onChangeType) => {
    const filter = selectedFilters;
    filter[value.key] = value.options;

    setSelectedFilters(filter);
  };

  const handleClear = () => {
    setSelectedFilters({});
    onClear({});
  };

  const handleDone = (value: selectedFilter) => {
    setSelectedFilters(value);
    onDone(value);
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    if (searchValue) {
      handleSelectedFilter({
        key: "search",
        options: {
          key: "equal" as OptionKeys,
          value: searchValue,
        },
      });
    } else {
      handleSelectedFilter({
        key: "search",
      });
    }
    onDone(selectedFilters);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value)

    if (value === "") {
      handleSearch(event)
    }
  }

  return (
    <div className="flex items-center space-x-4 py-2">
      <div>
        <form onSubmit={handleSearch} className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className="focus:border-primary-500 focus:border block w-full pl-10 sm:text-sm shadow rounded-md border-0"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            onBlur={handleSearch}
          />
        </form>
      </div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md shadow px-4 py-2 bg-white text-sm text-gray-900 focus:outline-none">
            <FilterIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span>Filter</span>
            {Object.entries(selectedFilters).filter(
              ([key, value]) => value && key !== "search"
            ).length > 0 && (
                <span className="text-primary-200 pl-2 ml-2 -mr-1 border-l">
                  {
                    Object.entries(selectedFilters).filter(
                      ([key, value]) => value && key !== "search"
                    ).length
                  }
                </span>
              )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white focus:outline-none">
            <div className="p-2 flex justify-between bg-gray-100">
              <button
                type="button"
                className="inline-flex items-center px-2 py-1 shadow text-xs font-medium rounded text-gray-900 bg-white focus:outline-none"
                onClick={() => handleClear()}
              >
                Clear
              </button>
              Filters
              <button
                type="button"
                className="inline-flex items-center px-2 py-1 shadow text-xs font-medium rounded bg-primary-100 text-primary-1000 focus:outline-none"
                onClick={() => handleDone(selectedFilters)}
              >
                Done
              </button>
            </div>
            <fieldset className="divide-y dividegray-100">
              <legend className="sr-only">Filters</legend>
              {fields
                .filter((field) => field.type !== "search")
                .map((field) => {
                  return (
                    <Menu.Item key={field.key}>
                      {({ active }) => (
                        <Option
                          type={field.type as filterOptions}
                          options={field.options}
                          subtype={field.subtype}
                          selected={selectedFilters[field.key]}
                          active={active}
                          label={field.label}
                          filterKey={field.key}
                          onChange={handleSelectedFilter}
                          checked={selectedFilters[field.key] !== undefined}
                        />
                      )}
                    </Menu.Item>
                  );
                })}
            </fieldset>
          </Menu.Items>
        </Transition>
      </Menu>
      <CSVLink
        data={formatData(data)}
        filename={`${location}-${new Date().toISOString()}.csv`}
        role="button"
        className="inline-flex justify-center rounded-md shadow px-4 py-2 bg-white text-sm text-gray-900 focus:outline-none"
      >
        Export
      </CSVLink>
    </div>
  );
}
