
import TableLayout from "../Layouts/TableLayout";
import { useHistory } from 'react-router';
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const SignupsIndex = ({ url }: { url?: string }) => {
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {},
    },
    {
      key: "pending",
      label: "Pending",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["pending"],
        },
      },
    },
    {
      key: "approved",
      label: "Approved",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["approved"],
        },
      },
    },
  ];
  const defaultFilter = "pending";
  const history = useHistory();
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const [tableData, headers, count] = useApiHook("/signups", routeQuery);

  const handleRowClick = (row: any) => {
    history.push(`/clubs/${row.clubId}`)
  }

  return (
    <div>
      <TableLayout
        rows={tableData}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        defaultFilter={defaultFilter}
        onRowClick={handleRowClick}
        tabs={tabs}
      />
    </div>
  );
};

export default SignupsIndex;
