/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { classNames } from "../../lib/helpers";

export default function Select({
  options = [],
  label,
  placeholder = "Select an option",
  defaultValue,
  onChange = () => {},
}: {
  options: filterObject[] | selectOptions[];
  label?: String;
  placeholder?: String;
  defaultValue?: filterObject | selectOptions;
  onChange: (value: filterObject | selectOptions) => void;
}) {
  const [selected, setSelected] = useState(defaultValue || options[0]);

  const handleChange = (value: filterObject) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {label && (
        <Listbox.Label className="block text-sm font-medium text-gray-900">
          {label}
        </Listbox.Label>
      )}
      <div className="relative">
        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-primary-500 sm:text-sm cursor-pointer">
          <span className="block truncate">{selected.name || placeholder}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={option.key}
                className={({ active }) =>
                  classNames(
                    active ? "bg-gray-100" : "",
                    "cursor-default select-none relative py-2 pl-8 pr-4 text-gray-900"
                  )
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      )}
                    >
                      {option.name}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? "text-primary-600" : "text-primary-600",
                          "absolute inset-y-0 left-0 flex items-center pl-1.5"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
