export const pageTitles: { [key: string]: string } = {
  "/affiliates": "Affiliates",
  "/clubs": "Clubs",
  "/onboarding": "Onboarding",
  "/payments": "Payments",
  "/payouts": "Payouts",
  "/signups": "Signups",
  "/settings": "Settings",
  "/": "Dashboard",
};
