import { handleDataFormat } from "../../lib/helpers";

const Stats = ({ stats }: { stats: StatsObject[] }) => {
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 pb-4">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="px-5 py-4 bg-white shadow rounded-lg overflow-hidden sm:px-5 sm:py-4"
        >
          <dt className="text-gray-900 truncate text-base">{stat.label}</dt>
          <dd className="mt-1 text-3xl font-medium text-gray-900">
            {stat.format
              ? handleDataFormat(stat.value, stat.format)
              : stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default Stats;
