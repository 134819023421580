import React from "react";
import moment from "moment";
import { useHistory } from "react-router";

import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const ClubsIndex = ({ url }: { url?: string }) => {
  const history = useHistory();
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {},
    },
    {
      key: "active",
      label: "Active",
      filterPreset: {
        lastPayment: {
          key: "gt" as OptionKeys,
          value: moment().subtract(1, "year").format("YYYY-MM-DD"),
        },
      },
    },
    {
      key: "inactive",
      label: "Inactive",
      filterPreset: {
        lastPayment: {
          key: "lt" as OptionKeys,
          value: moment().subtract(1, "year").format("YYYY-MM-DD"),
        },
      },
    },
  ];
  const defaultFilter = "active";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const [tableData, headers, count] = useApiHook("/clubs", routeQuery);

  const handleRowClick = (row: any) => {
    history.push("/clubs/" + row.id);
  };

  return (
    <div>
      <TableLayout
        rows={tableData}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        onRowClick={handleRowClick}
        defaultFilter={defaultFilter}
        tabs={tabs}
      />
    </div>
  );
};

export default ClubsIndex;
