const DisconnectAccountConfirmation = ({
  organisation,
}: {
  organisation: any;
}) => {
  return (
    <div className="space-y-4">
      <p>Are you sure you want to disconnect from {organisation.name}</p>
    </div>
  );
};

export default DisconnectAccountConfirmation;
