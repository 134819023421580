const ConnectAccountConfirmation = ({
  organisation,
}: {
  organisation: any;
}) => {
  return (
    <div className="space-y-4">
      <p>Are you sure you want to connect to {organisation.name}</p>
    </div>
  );
};

export default ConnectAccountConfirmation;
