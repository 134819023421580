import moment from "moment";
import Stats from "../Components/Stats";

import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";
import { useHistory } from "react-router";

const DashboardPage = () => {
  const history = useHistory();
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
    {
      key: "active",
      label: "Active",
      filterPreset: {
        paymentDate: {
          key: "between" as OptionKeys,
          value: {
            start: moment().subtract(1, "year").format("YYYY-MM-DD"),
            end: moment().subtract(1, "day").format("YYYY-MM-DD"),
          },
        },
        method: {
          key: "equal" as OptionKeys,
          value: ["Card"],
        },
      },
    },
  ];
  const defaultFilter = "active";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const [tableData, headers, count, stats] = useApiHook("/", routeQuery);


  const handleRowClick = (row: any) => {
    history.push(`/clubs/${row.clubId}`)
  }

  return (
    <div>
      <Stats stats={stats} />
      <TableLayout
        rows={tableData}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        defaultFilter={defaultFilter}
        tabs={tabs}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default DashboardPage;
