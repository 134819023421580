import React from "react";
import { classNames } from "../../lib/helpers";

const Input = ({
  value,
  onChange,
  id,
  name,
  label,
  placeholder,
  labelHidden,
}: {
  placeholder?: string;
  value: string | number;
  onChange: Function;
  id?: string;
  name?: string;
  label?: string;
  labelHidden?: boolean;
  mask?: string;
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          labelHidden
            ? "sr-only"
            : "block text-sm font-medium text-gray-700 mb-1"
        )}
      >
        {label}
      </label>
      <input
        type="text"
        name={name || id}
        id={id}
        className="focus:border-primary-500 block w-full sm:text-sm border-gray-200 rounded-md"
        placeholder={placeholder}
        onChange={(event) => onChange(event)}
        value={value}
      />
    </div>
  );
};

export default Input;
