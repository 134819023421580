import React, { useState, useRef, useEffect } from "react";
import Modal from "../Modal";
import { Dialog } from "@headlessui/react";
import { classNames } from "../../lib/helpers";

const ConfirmationModal = ({
  title = "",
  callback,
  danger,
  open,
  modal,
  props = {},
  setOpen,
}: {
  title: string;
  callback: Function;
  danger?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  modal: confirmationModel;
  props?: any;
}) => {
  const cancelButtonRef = useRef(null);
  const [formValue, setFormValue] = useState<{ [key: string]: any }>({});

  const handleFormValue = (event: { name: string; value: any }) => {
    setFormValue((data) => {
      data[event.name] = event.value;
      return data;
    });
  };

  useEffect(() => {
    if (!open) {
      setFormValue({});
    }
  }, [open]);

  return (
    <Modal width="w-xl" open={open} setOpen={setOpen}>
      {/*
      For some reason wrapping this in a form component was causing the form to append the values to the page path
      <form
        action=""
        onSubmit={(event) => {
          console.log(formValue);
          callback(formValue);
        }}
      > */}
      <div className="p-4 text-center sm:mt-0 sm:text-left border-b border-gray-200">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          {title}
        </Dialog.Title>
      </div>
      <div className="px-4 pt-5 pb-4 sm:p-4 rounded-t-lg bg-gray-100 border-b border-gray-200">
        <div className="text-sm">
          {React.createElement(modal.body, {
            ...props,
            setFormValue: handleFormValue,
          })}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
        <button
          type="submit"
          className={classNames(
            danger ? "bg-red-600" : "bg-primary-100 text-primary-1000",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          )}
          onClick={() => {
            callback(formValue);
            setOpen(false);
          }}
        >
          {modal.button && modal.button.submit && modal.button
            ? modal.button.submit.text
            : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-900 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
      {/* </form> */}
    </Modal>
  );
};

export default ConfirmationModal;
