import React, { useEffect, useState } from "react";
import Input from "../../Input";

export default function Single({
  onChange,
  optionKey,
  value = "",
}: filterProps) {
  const [inputValue, setInputValue] = useState(value);
  const labels: labelObject = {
    equal: {
      label: "Equal to",
      id: "equalTo",
    },
    lt: {
      label: "Less than",
      id: "lessThan",
    },
    gt: {
      label: "Greater than",
      id: "greaterThan",
    },
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target);
    setInputValue(event.target.value);
  };

  return (
    <div className="pt-2">
      {optionKey && (
        <Input
          labelHidden
          label={labels[optionKey]?.label}
          name={labels[optionKey]?.id}
          id={labels[optionKey]?.id}
          placeholder="0"
          onChange={handleChange}
          value={inputValue}
        />
      )}
    </div>
  );
}
