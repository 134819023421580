import { useHistory, useLocation } from "react-router";
import { classNames } from "../../lib/helpers";

const Tabs = ({
  tabs,
  children,
  updateFilter,
  currentFilter,
}: {
  tabs: tabObject[];
  children: React.ReactNode;
  updateFilter: (filterPreset: selectedFilter) => void;
  currentFilter: selectedFilter;
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (tab: tabObject) => {
    if (tab.filterPreset) {
      // Clone preset object because object mutability is occuring
      updateFilter(JSON.parse(JSON.stringify(tab.filterPreset)));
    } else if (tab.hash) {
      history.push(location.pathname + tab.hash);
    }
  };

  return (
    <div>
      {tabs.length > 0 && (
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-primary-500 sm:text-sm rounded-md"
            defaultValue={tabs[0].label}
          >
            {tabs.map((tab) => (
              <option key={tab.label}>{tab.label}</option>
            ))}
          </select>
        </div>
      )}
      <div className="hidden sm:block">
        <div
          className={classNames(
            tabs.length ? "justify-between" : "justify-end",
            "flex items-center"
          )}
        >
          <nav className="-mb-px flex space-x-2" aria-label="Tabs">
            {tabs.map((tab, index) => {
              const active =
                JSON.stringify(currentFilter) ===
                  JSON.stringify(tab.filterPreset) ||
                location.hash === tab.hash ||
                (!tab.filterPreset && !location.hash && index === 0);

              return (
                <div
                  key={tab.label}
                  className={classNames(
                    active
                      ? "bg-gray-200 text-gray-800"
                      : "text-gray-600 hover:text-gray-800",
                    "px-3 py-2 text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={active ? "page" : undefined}
                  onClick={() => handleClick(tab)}
                >
                  {tab.label}
                </div>
              );
            })}
          </nav>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Tabs;
