import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation, withRouter, useHistory } from "react-router-dom";

import "./App.css";
import DashboardLayout from "./Layouts/DashboardLayout";
import { pageTitles } from "./lib/titles";
import AffiliatePage from "./Pages/AffiliatePage";
import AffiliatesIndex from "./Pages/AffiliatesIndex";
import ClubPage from "./Pages/ClubPage";
import ClubsIndex from "./Pages/ClubsIndex";

import Dashboard from "./Pages/Dashboard";
import OnboardingIndex from "./Pages/OnboardingIndex";
import PayeePayoutPage from "./Pages/PayeePayoutPage";
import PaymentsIndex from "./Pages/PaymentsIndex";
import PayoutPage from "./Pages/PayoutPage";
import PayoutsIndex from "./Pages/PayoutsIndex";
import SettingsPage from "./Pages/SettingsPage";
import Signin from "./Pages/Signin";
import SignupsIndex from "./Pages/SignupsIndex";

import { Auth } from 'aws-amplify'

function useAuthenticator(content: any) {
  const [user, updateUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(data => {
      updateUser(data);
    }).catch(err => {
      console.error(err);
      updateUser(null);
    });
  }, [Auth.currentAuthenticatedUser()])

  if (user) {
    return true
  } else {
    return false
  }
}

function App() {
  const [title, setTitle] = useState("Dashboard");
  const [user, setUser] = useState(null);

  const location = useLocation();

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (err) {
      console.error(err);
      setUser(null);
    }
  }

  useEffect(() => {
    checkAuth();
  }, [])

  useEffect(() => {
    setTitle(pageTitles[location.pathname]);
    checkAuth();
  }, [location]);

  return (
    <>
      {user ?
        <Switch>
          <Route path="/">
            <div className="App">
              <DashboardLayout title={title}>
                <Switch>
                  <Route exact path="/affiliates">
                    <AffiliatesIndex />
                  </Route>
                  <Route exact path="/affiliates/:id" component={AffiliatePage} />
                  <Route exact path="/clubs">
                    <ClubsIndex />
                  </Route>
                  <Route exact path="/clubs/:id" component={ClubPage} />
                  <Route exact path="/onboarding">
                    <OnboardingIndex />
                  </Route>
                  <Route exact path="/payments">
                    <PaymentsIndex />
                  </Route>
                  <Route exact path="/payouts">
                    <PayoutsIndex />
                  </Route>
                  <Route exact path="/payouts/:id" component={PayoutPage} />
                  <Route
                    exact
                    path="/payouts/:id/payees/:payeeId"
                    component={PayeePayoutPage}
                  />
                  <Route exact path="/signups">
                    <SignupsIndex />
                  </Route>
                  <Route exact path="/settings">
                    <SettingsPage />
                  </Route>
                  <Route path="/">
                    <Dashboard />
                  </Route>
                </Switch>
              </DashboardLayout>
            </div>
          </Route>
        </Switch>
        : <Signin />
      }
    </>
  )
}

export default App;


