import React from "react";
import { useHistory } from "react-router";

import TableLayout from "../Layouts/TableLayout";
import { useApiHook, useRouteQueryHook } from "../lib/hooks";

const AffiliatesIndex = ({ url }: { url?: string }) => {
  const tabs = [
    {
      key: "all",
      label: "All",
      filterPreset: {},
    },
    {
      key: "pending",
      label: "Pending",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["pending"],
        },
      },
    },
    {
      key: "approved",
      label: "Approved",
      filterPreset: {
        status: {
          key: "equal" as OptionKeys,
          value: ["approved"],
        },
      },
    },
  ];
  const defaultFilter = "pending";
  const [routeQuery, generateUrl] = useRouteQueryHook(tabs, defaultFilter);

  const history = useHistory();

  const [tableData, headers, count] = useApiHook("/affiliates", routeQuery);

  const handleRowClick = (row: any) => {
    history.push("/affiliates/" + row.id);
  };

  return (
    <div>
      <TableLayout
        rows={tableData.sort((a: any, b: any) => b.dateCreated.localeCompare(a.dateCreated))}
        headers={headers}
        count={count}
        handleFiltering={generateUrl}
        onRowClick={handleRowClick}
        defaultFilter={defaultFilter}
        tabs={tabs}
      />
    </div>
  );
};

export default AffiliatesIndex;
