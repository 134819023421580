import * as React from "react";
import Checkbox from "../../Input/Checkbox";

export default function Multiple({
  options = [],
  onChange,
  value = [],
}: filterProps) {
  const [filterOptions, setOptions] = React.useState([] as Array<option>);
  const [selectedOptions, setSelectedOption] = React.useState(
    options.filter((option) => value.includes(option.value))
  );

  React.useEffect(() => {
    setOptions(
      options.map((option) => ({
        ...option,
        checked: value.includes(option.value),
      }))
    );
  }, [options, value]);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let currentOptions: option[];
    if (
      selectedOptions.map((option) => option.value).includes(event.target.value)
    ) {
      currentOptions = selectedOptions.filter(
        (option) => option.value !== event.target.value
      );
      setSelectedOption(currentOptions);
    } else {
      currentOptions = [
        ...selectedOptions,
        {
          value: event.target.value,
          label: event.target.name,
          checked: true,
        },
      ];
      setSelectedOption(currentOptions);
    }

    if (currentOptions.length) {
      onChange({ value: currentOptions.map((op) => op.value) });
    } else {
      onChange({ value: "" });
    }

    setOptions((currentOptions) => {
      return currentOptions.map((option) => {
        if (option.value === event.target.value) {
          return {
            ...option,
            checked: event.target.checked,
          };
        }
        return option;
      });
    });
  };

  return (
    <fieldset className="space-y-2">
      {filterOptions.map((option: option) => {
        return (
          <Checkbox
            key={option.value}
            value={option.value}
            label={option.label}
            checked={option.checked}
            onChange={handleCheck}
            className="font-light"
          />
        );
      })}
    </fieldset>
  );
}
