import moment from "moment";

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

export const formatDateForExport = (date: string) => {
  const dateObj = moment(date);

  const newDate = {
    date: dateObj.format("DD/MM/YYYY"),
    time: dateObj.format("HH:mm"),
  };

  return newDate;
};

export const formatMoney = (value: number): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  }).format(value);
};

export const formatNumber = (value: number): string => {
  return value.toLocaleString("en-GB");
};

export const generateQuery = (page: number, filter: { [key: string]: any }) => {
  const query = Object.entries(filter)
    .map(([key, filterValue]: [string, any]) => {
      if (typeof filterValue === "string" || typeof filterValue === "number") {
        return `${key}=${filterValue}`;
      } else if (filterValue) {
        switch (filterValue.key) {
          case "between":
            return `${key}[${filterValue.key}]=${filterValue.value.start},${filterValue.value.end}`;
          case "equal":
            if (key.toLowerCase().includes("date")) {
              return `${key}[between]=${filterValue.value},${filterValue.value}`;
            }
            return `${key}=${filterValue.value}`;
          default:
            return `${key}[${filterValue.key}]=${filterValue.value}`;
        }
      }
      return "";
    })
    .filter((query) => query);

  if (page) {
    query.push("page=" + page);
  }

  if (query.length) {
    return "?" + query.join("&");
  }

  return "";
};

export const handleDataFormat = (
  value: string | number | any,
  format: string
) => {
  switch (format) {
    case "onlineStatus":
      if (value === "online") {
        return `<span class='text-green-200'>${value}</span>`;
      }
      return `<span class='text-red-200'>${value}</span>`;
    case "number":
      return formatNumber(value);

    case "currency":
      return formatMoney(value / 100);
    case "date":
      const date = moment(value);
      if (date.isValid()) {
        return date.format("DD MMM YYYY");
      }
      return value;
    case "dateTime":
      return moment(value).format("DD MMM YYYY HH:mm");
    default:
      return value;
  }
};
