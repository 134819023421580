import { useEffect, useState } from "react";
import Card from "../Components/Card";
import ConfirmationModal from "../Components/Confirmation";
import Table from "../Components/Table";
import { useAxiosInstance } from "../lib/hooks";
import {
  createCredit,
  createNewFee,
  disconnectAccount,
  turnOffDemo,
  turnOnDemo,
  createDemoPayout,
} from "../lib/modals";
import axios from "axios";

const SettingsPage = () => {
  const instance = useAxiosInstance();
  const [confirmation, setConfirmation] = useState<confirmationModel>();
  const [open, setOpen] = useState(false);
  const [creditsData, setCredits] = useState([]);
  const [feeData, setFees] = useState([]);
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [disconnectProps, setDisconnectedProps] = useState({});
  const [demoPayzipStatus, setDemoPayzipStatus] = useState("offline");
  const [demoGenieStatus, setDemoGenieStatus] = useState("offline");

  useEffect(() => {
    instance.get("/vouchers").then(({ data }) => {
      setCredits(data);
    });
  }, [instance]);

  useEffect(() => {
    instance.get("/fees").then(({ data }) => {
      setFees(data);
    });
  }, [instance]);

  useEffect(() => {
    instance.get("/connected").then(({ data }) => {
      setConnectedAccounts(data);
    });
  }, [instance]);

  useEffect(() => {
    axios
      .get("https://demo.payzip.co.uk/api/status")
      .then(() => {
        setDemoPayzipStatus("online");
      })
      .catch(() => {
        setDemoPayzipStatus("offline");
      });
    axios
      .get("https://demo.payzip.co.uk/genie/status")
      .then(() => {
        setDemoGenieStatus("online");
      })
      .catch(() => {
        setDemoGenieStatus("offline");
      });
  }, []);

  const disconnectTheAccount = (id: Number) => {
    instance
      .post(`/clubs/${id}/disconnect`)
      .then(() => {
        instance.get("/connected").then(({ data }) => {
          setConnectedAccounts(data);
        });
      })
      .catch((e: Error) => {
        alert(`Unable to disconnect from account: ${e.message}`);
      });
  };

  const handleRowClick = (row: any) => {
    setDisconnectedProps({
      organisation: {
        name: row.name,
      },
    });
    setConfirmation(disconnectAccount(() => disconnectTheAccount(row.id)));
    setOpen(true);
  };

  const handleSubmit = (value: any) => {
    if (confirmation) {
      setOpen(false);
      confirmation.callback(value);
    }
  };

  const createCreditCallback = (value: any) => {
    instance.post("/vouchers", value).then(({ data }) => {
      setCredits(data);
    });
  };

  const createFeeCallback = (value: any) => {
    instance.post("/fees", value).then(({ data }) => {
      setFees(data);
    });
  };

  const turnOnDemoCallback = () => {
    axios.get(
      "https://bjt31nitnd.execute-api.eu-west-2.amazonaws.com/default/overwatch",
      {
        headers: {
          "x-api-key": "vEdWOXFXrL3XqB9WBaFzf5N9z4Cg4WcQioB987ic",
        },
      }
    );
  };

  const turnOffDemoCallback = () => {
    turnOnDemoCallback();
    setDemoPayzipStatus("offline");
    setDemoGenieStatus("offline");
  };

  const startDemoPayoutCallback = () => {
    axios.post("https://demo.payzip.co.uk/genie/webhook", {
      type: "payments.payout",
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
      <Card
        title="Credits"
        action={{
          label: "Add credit",
          callback: () => {
            setConfirmation(createCredit(createCreditCallback));
            setOpen(true);
          },
        }}
      >
        <Table
          shadow={false}
          rounded={false}
          hidePagination
          count={creditsData.length}
          rows={creditsData}
          headers={[
            {
              label: "Name",
              key: "name",
            },
            {
              label: "Value",
              key: "volume",
              type: "number" as filterOptions,
              format: "currency",
            },
          ]}
        />
      </Card>
      <Card
        title="Fees"
        action={{
          label: "Add fee",
          callback: () => {
            setConfirmation(createNewFee(createFeeCallback));
            setOpen(true);
          },
        }}
      >
        <Table
          shadow={false}
          rounded={false}
          hidePagination
          count={feeData.length}
          rows={feeData}
          headers={[
            {
              label: "Name",
              key: "internalName",
            },
          ]}
        />
      </Card>
      <Card title="Connected Accounts" action={null}>
        <Table
          shadow={false}
          rounded={false}
          hidePagination
          count={0}
          rows={connectedAccounts}
          onRowClick={handleRowClick}
          headers={[
            {
              label: "Name",
              key: "name",
            },
          ]}
        />
      </Card>
      <Card
        title="Demo Status"
        action={{
          label: `Turn ${
            demoPayzipStatus == "online" && demoGenieStatus == "online"
              ? "off"
              : "on"
          }`,
          callback: () => {
            if (demoPayzipStatus == "online" && demoGenieStatus == "online") {
              setConfirmation(turnOffDemo(turnOffDemoCallback));
            } else {
              setConfirmation(turnOnDemo(turnOnDemoCallback));
            }
            setOpen(true);
          },
        }}
        secondAction={{
          label: "Create payout",
          callback: () => {
            setConfirmation(createDemoPayout(startDemoPayoutCallback));
            setOpen(true);
          },
        }}
      >
        <Table
          shadow={false}
          rounded={false}
          hidePagination
          count={0}
          rows={[
            {
              service: "Frontend",
              status:
                demoPayzipStatus == "online" && demoGenieStatus == "online"
                  ? "online"
                  : "offline",
            },
            {
              service: "Payzip API",
              status: demoPayzipStatus,
            },
            {
              service: "Genie API",
              status: demoGenieStatus,
            },
          ]}
          headers={[
            {
              label: "Service",
              key: "service",
            },
            {
              label: "Status",
              key: "status",
              format: "onlineStatus",
            },
          ]}
        />
      </Card>
      {confirmation && (
        <ConfirmationModal
          title={confirmation.title}
          callback={handleSubmit}
          open={open}
          setOpen={setOpen}
          modal={confirmation}
          props={disconnectProps}
        />
      )}
    </div>
  );
};

export default SettingsPage;
