import React from "react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
// import { classNames } from "../../lib/helpers";

const Pagination = ({
  page,
  count,
  onPageChange,
  limit = 50,
}: PaginationProps) => {
  // const [pageCount] = useState(Math.ceil(count / limit));

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="py-2 text-sm">
        <span className="font-medium">{count}</span> results
      </div>
      {/* <div className="flex-1 flex justify-between sm:hidden">
        <div className="relative inline-flex items-center h-8 w-8 justify-center border border-gray-200 text-sm font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
          Previous
        </div>
        <div className="ml-3 relative inline-flex items-center h-8 w-8 justify-center border border-gray-200 text-sm font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
          Next
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-900 py-2 my-px">
            Showing{" "}
            <span className="font-medium">
              {count > 0 ? (page - 1) * limit + 1 : 0}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {page * limit < count ? page * limit : count}
            </span>{" "}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        {count > limit && (
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <div
                onClick={() => {
                  if (page - 1) {
                    onPageChange(page - 1);
                  }
                }}
                className={classNames(
                  page === 1
                    ? "text-gray-300"
                    : "text-gray-500 cursor-pointer hover:bg-gray-50",
                  "relative inline-flex items-center h-8 w-8 justify-center rounded-l-md border border-gray-200 bg-white text-sm font-medium"
                )}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              {pageCount > 7 ? (
                <div>
                  <div
                    className={classNames(
                      page === 1
                        ? "bg-gray-100 z-10 font-medium relative"
                        : "hover:bg-gray-50",
                      "border-gray-200 bg-white text-gray-500 relative inline-flex items-center h-8 w-8 justify-center border-t border-b border-l last:border-r text-sm font-medium cursor-pointer"
                    )}
                    onClick={() => onPageChange(1)}
                  >
                    1
                  </div>

                  {page > 5 && (
                    <div
                      className="bg-white text-gray-500 border-gray-200 relative inline-flex items-center h-8 w-8 justify-center border-t border-b border-l last:border-r text-sm font-medium cursor-pointer"
                      onClick={() => onPageChange(page - 3)}
                    >
                      ...
                    </div>
                  )}
                  {[...Array(pageCount)]
                    .map((_, index) => (
                      <div
                        key={"page" + (index + 2)}
                        className={classNames(
                          page === index + 2
                            ? "bg-gray-100 z-10 font-medium relative"
                            : "hover:bg-gray-50",
                          "border-gray-200 bg-white text-gray-500 relative inline-flex items-center h-8 w-8 justify-center border-t border-b border-l last:border-r text-sm font-medium cursor-pointer"
                        )}
                        onClick={() => onPageChange(index + 2)}
                      >
                        {index + 2}
                      </div>
                    ))
                    .filter((_, index) => {
                      const y = index + 1;
                      const yMax = page === 5 ? y >= page - 4 : y >= page - 3;
                      const yMin =
                        page === pageCount - 4 ? y <= page + 2 : y <= page + 1;

                      return y !== 0 && y < pageCount - 1 && yMax && yMin;
                    })}

                  {page < pageCount - 4 && (
                    <div
                      className="bg-white text-gray-500 border-gray-200 relative inline-flex items-center h-8 w-8 justify-center border-t border-b border-l last:border-r text-sm font-medium cursor-pointer"
                      onClick={() => onPageChange(page + 3)}
                    >
                      ...
                    </div>
                  )}

                  <div
                    className={classNames(
                      page === pageCount
                        ? "bg-gray-100 z-10 font-medium relative"
                        : "hover:bg-gray-50",
                      "border-gray-200 bg-white text-gray-500 relative inline-flex items-center h-8 w-8 justify-center border-t border-b border-l border-r text-sm font-medium cursor-pointer"
                    )}
                    onClick={() => onPageChange(pageCount)}
                  >
                    {pageCount}
                  </div>
                </div>
              ) : (
                [...Array(pageCount)].map((_, index) => {
                  return (
                    <div
                      className={classNames(
                        page === index + 1
                          ? "bg-gray-100 z-10 font-medium relative"
                          : "hover:bg-gray-50",
                        "border-gray-200 bg-white text-gray-500 hover:bg-gray-50 relative inline-flex items-center h-8 w-8 justify-center border text-sm font-medium cursor-pointer"
                      )}
                      onClick={() => onPageChange(index + 1)}
                    >
                      {index + 1}
                    </div>
                  );
                })
              )}
              <div
                onClick={() => {
                  if (page + 1 <= pageCount) {
                    onPageChange(page + 1);
                  } else {
                    onPageChange(pageCount);
                  }
                }}
                className={classNames(
                  page === pageCount
                    ? "text-gray-300"
                    : "text-gray-500 cursor-pointer hover:bg-gray-50",
                  "relative inline-flex items-center h-8 w-8 justify-center rounded-r-md border border-gray-200 bg-white text-sm font-medium"
                )}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </nav>
          </div>
        )}
      </div> */}
    </div>
  );
};
export default Pagination;
