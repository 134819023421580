import React from "react";
import { classNames } from "../../lib/helpers";

const Checkbox = ({
  value,
  checked,
  label,
  onChange,
  className,
}: {
  value: string;
  checked?: boolean;
  label: string;
  onChange: Function;
  className?: string;
}) => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={value}
          aria-describedby={`${value}-description`}
          name={value}
          type="checkbox"
          value={value}
          checked={checked}
          className="h-4 w-4 text-primary-400 border-gray-300 rounded focus:outline-none"
          onChange={(event) => onChange(event)}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={value}
          className={classNames(
            "text-gray-900 cursor-pointer",
            className as string
          )}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
